<template>
	<div>
		<div class="bottom" :style="'background-image:' + `url(${require('@/assets/img/advantageDistribution.png')})`">
			<div class="bottom_div">
				<div class="dt_div_left" v-for="(item,index) in $t('top.tabsList')">
					<div>
						<a class="dt_div_left_title" @click="tabsClick(index)">{{item.name}}</a>
						
						<a class="dt_div_left_title2" v-for="(item2,index2) in item.list" :key="index2" @click="tabsListClick(item2)">{{item2.name}}</a>
					</div>
				</div>
			</div>
			<div style="color: #fff;padding-top: 20px;text-align: center;font-size: 12px;">
				深圳市格物思信息科技有限公司
				<a style="color: #fff;padding-left: 10px;" href="https://beian.miit.gov.cn/">粤ICP备2023044805号</a>
			</div>
		</div>
	</div>
</template>

<script>
	import { scrollTo } from '@/utils/scroll-to'
	export default {
		data() {
			return {
				route:'',//route路由
			}
		},

		created() {
			this.route=this.$route.path;
			// console.log(this.$route)
		},

		mounted() {

		},

		watch: {

		},

		computed: {

		},

		methods: {
			//---------------锚点跳转----------------
			goAnchor: function(selector) {
				switch (selector) {
					case 'home':
						if(this.route!=='/'){
							this.$router.push({
								path: '/',
							})
						}else{
							scrollTo(0,100)
						}
						break;
					case '#valuation':
						if(this.route!=='/valuation'){
							this.$router.push({
								path: '/valuation',
								query:{
									selector:selector
								}
							})
						}else{
							document.querySelector(selector).scrollIntoView({
								behavior: "smooth"
							})
						}
						break;
					default:
						break;
				}
			},
			
			tabsClick: function(index) {
				// this.tabsIndex = index
				this.drawer=false;
				switch (index) {
					case 0:
						if(this.$route.path=='/'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/',
								query:{
									selector:null
								}
							})
						}
						break;
					case 1:
						if(this.$route.path=='/valuation'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/valuation',
							})
						}
						break;
					case 2:
						if(this.$route.path=='/boardMaking/pcbBoardMaking'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/boardMaking/pcbBoardMaking',
							})
						}
						break;
					case 3:
						if(this.$route.path=='/manufacture/pcbaMade'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/manufacture/pcbaMade',
							})
						}
						break;
					case 4:
						if(this.$route.path=='/bomSupply'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/bomSupply',
							})
						}
						break;
					case 5:
						if(this.$route.path=='/solution/schemeDesign'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/solution/schemeDesign',
							})
						}
						break;
					case 6:
						if(this.$route.path=='/factoryDisplay/displayFactory'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/factoryDisplay/displayFactory',
							})
						}
						break;
					case 7:
						if(this.$route.path=='/aboutUs/aboutUs'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/aboutUs/aboutUs',
							})
						}
						break;
					default:
						break;
				}
			},
			
			//-------------tabs列表跳转--------------------------
			tabsListClick:function(item){
				console.log(item.id)
				switch (item.id) {
					case 1:
						if(this.$route.path=='/boardMaking/pcbBoardMaking'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/boardMaking/pcbBoardMaking',
							})
						}
						break;
					case 2:
						if(this.$route.path=='/boardMaking/pcbBatch'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/boardMaking/pcbBatch',
							})
						}
						break;
					case 3:
						if(this.$route.path=='/boardMaking/fpcBatch'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/boardMaking/fpcBatch',
							})
						}
						break;
					case 4:
						if(this.$route.path=='/boardMaking/aluminumSubstrate'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/boardMaking/aluminumSubstrate',
							})
						}
						break;
					case 5:
						if(this.$route.path=='/manufacture/pcbaMade'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/manufacture/pcbaMade',
							})
						}
						break;
					case 6:
						if(this.$route.path=='/manufacture/steelMesh'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/manufacture/steelMesh',
							})
						}
						break;
					case 7:
						if(this.$route.path=='/solution/schemeDesign'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/solution/schemeDesign',
							})
						}
						break;
					case 8:
						if(this.$route.path=='/solution/layoutDesign'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/solution/layoutDesign',
							})
						}
						break;
					case 9:
						if(this.$route.path=='/factoryDisplay/displayFactory'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/factoryDisplay/displayFactory',
							})
						}
						break;
					case 10:
						if(this.$route.path=='/factoryDisplay/iaminatedStructure'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/factoryDisplay/iaminatedStructure',
							})
						}
						break;
					case 11:
						if(this.$route.path=='/factoryDisplay/processShow'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/factoryDisplay/processShow',
							})
						}
						break;
					case 12:
						if(this.$route.path=='/factoryDisplay/productList'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/factoryDisplay/productList',
							})
						}
						break;
					case 13:
						if(this.$route.path=='/factoryDisplay/activity'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/factoryDisplay/activity',
							})
						}
						break;
					case 14:
						if(this.$route.path=='/factoryDisplay/sunbathing'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/factoryDisplay/sunbathing',
							})
						}
						break;
					case 15:
						if(this.$route.path=='/aboutUs/aboutUs'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/aboutUs/aboutUs',
							})
						}
						break;
					case 16:
						if(this.$route.path=='/aboutUs/contactUs'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/aboutUs/contactUs',
							})
						}
						break;
					case 17:
						if(this.$route.path=='/aboutUs/news'){
							this.$router.go(0)
						}else{
							this.$router.push({
								path: '/aboutUs/news',
							})
						}
						break;
					
					default:
						break;
				}
			},
		}
	}
</script>

<style lang="less" scoped>
	@media only screen and (min-width:1920px) {
		.btn {
			margin-top: 20px;
			width: 150px;
			line-height: 45px;
			height: 45px;
			border: 1px solid #fff;
			color: #fff;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
			color: #fff;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.bottom {
			padding: 80px 200px;
		
			.bottom_div {
				display: flex;
				width: 100%;
				position: relative;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				padding-bottom: 40px;
		
				.dt_div_left {
					width: 85%;
					display: flex;
		
					.dt_div_left_title {
						font-size: 18px;
						color: #fff;
						line-height: 32px;
						display: block;
						padding-bottom: 10px;
						position: relative;
						cursor: pointer;
					}
		
					.dt_div_left_title2 {
						font-size: 14px;
						line-height: 30px;
						color: #ccc;
						display: block;
						cursor: pointer;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1920px) {
		.btn {
			margin-top: 20px;
			width: 150px;
			line-height: 45px;
			height: 45px;
			border: 1px solid #fff;
			color: #fff;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
			color: #fff;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.bottom {
			padding: 80px 200px;
		
			.bottom_div {
				display: flex;
				width: 100%;
				position: relative;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				padding-bottom: 40px;
		
				.dt_div_left {
					width: 85%;
					display: flex;
		
					.dt_div_left_title {
						font-size: 18px;
						color: #fff;
						line-height: 32px;
						display: block;
						padding-bottom: 10px;
						position: relative;
						cursor: pointer;
					}
		
					.dt_div_left_title2 {
						font-size: 14px;
						line-height: 30px;
						color: #ccc;
						display: block;
						cursor: pointer;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.btn {
			margin-top: 20px;
			width: 150px;
			line-height: 45px;
			height: 45px;
			border: 1px solid #fff;
			color: #fff;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
			color: #fff;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.bottom {
			padding: 80px 100px;
		
			.bottom_div {
				display: flex;
				width: 100%;
				position: relative;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				padding-bottom: 40px;
		
				.dt_div_left {
					width: 85%;
					display: flex;
		
					.dt_div_left_title {
						font-size: 18px;
						color: #fff;
						line-height: 32px;
						display: block;
						padding-bottom: 10px;
						position: relative;
						cursor: pointer;
					}
		
					.dt_div_left_title2 {
						font-size: 14px;
						line-height: 30px;
						color: #ccc;
						display: block;
						cursor: pointer;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.btn {
			margin-top: 20px;
			width: 150px;
			line-height: 45px;
			height: 45px;
			border: 1px solid #fff;
			color: #fff;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
			color: #fff;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.bottom {
			padding: 80px 60px;
		
			.bottom_div {
				display: flex;
				width: 100%;
				position: relative;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				padding-bottom: 40px;
		
				.dt_div_left {
					width: 85%;
					display: flex;
		
					.dt_div_left_title {
						font-size: 18px;
						color: #fff;
						line-height: 32px;
						display: block;
						padding-bottom: 10px;
						position: relative;
						cursor: pointer;
					}
		
					.dt_div_left_title2 {
						font-size: 14px;
						line-height: 30px;
						color: #ccc;
						display: block;
						cursor: pointer;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.btn {
			margin-top: 20px;
			width: 150px;
			line-height: 45px;
			height: 45px;
			border: 1px solid #fff;
			color: #fff;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
			color: #fff;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.bottom {
			padding: 40px;
		
			.bottom_div {
				display: flex;
				width: 100%;
				position: relative;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				padding-bottom: 40px;
		
				.dt_div_left {
					width: 85%;
					display: flex;
		
					.dt_div_left_title {
						font-size: 18px;
						color: #fff;
						line-height: 32px;
						display: block;
						padding-bottom: 10px;
						position: relative;
						cursor: pointer;
					}
		
					.dt_div_left_title2 {
						font-size: 14px;
						line-height: 30px;
						color: #ccc;
						display: block;
						cursor: pointer;
					}
				}
			}
		}
	}
	
	@media only screen and (max-width:767px) {
		.btn {
			margin-top: 20px;
			width: 150px;
			line-height: 45px;
			height: 45px;
			border: 1px solid #fff;
			color: #fff;
			font-family: BigJohn;
			font-weight: bold;
			text-align: center;
			border-radius: 5px;
			user-select: none;
			cursor: pointer;
			position: relative;
			font-size: 16px;
			overflow: hidden;
		}
		
		.btn .learnMore {
			width: 100%;
			height: 100%;
			background: linear-gradient(to right, #621ed2, #3066f5);
			// background-repeat: no-repeat;
			left: -100%;
			position: absolute;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore {
			left: 0%;
			color: #fff;
			// z-index: 4;
		}
		
		.btn .learnMore2 {
			position: relative;
			transition: 0.6s;
			color: #fff;
		}
		
		.btn:hover {
			border: 1px solid #fff;
			transition: 0.6s;
		}
		
		.btn:hover .learnMore2 {
			color: #fff;
			// z-index: 3;
		}
		
		.btn:active {
			box-shadow: 0 0 3px #ccc;
		}
		
		.bottom {
			padding: 20px;
		
			.bottom_div {
				display: flex;
				width: 100%;
				position: relative;
				border-bottom: 1px solid rgba(255, 255, 255, 0.1);
				padding-bottom: 40px;
		
				.dt_div_left {
					width: 85%;
					display: flex;
		
					.dt_div_left_title {
						font-size: 18px;
						color: #fff;
						line-height: 32px;
						display: block;
						padding-bottom: 10px;
						position: relative;
						cursor: pointer;
					}
		
					.dt_div_left_title2 {
						font-size: 14px;
						line-height: 30px;
						color: #ccc;
						display: block;
						cursor: pointer;
					}
				}
			}
		}
	}
	
</style>
