<template>
	<div>
		<div class="right">
			<div class="ri_top" @click="upTop">
				<a-icon type="up" class="ri_to_icon" />
			</div>
			<div class="ri_text">
				<el-tooltip placement="left">
				  <div slot="content">
					  <div class="ri_qq">
						  <div>{{$t('right.qq.text1')}}</div>
						  <div style="padding-top: 10px;padding-bottom: 10px;">{{$t('right.qq.text2')}}</div>
						  <el-link href="https://wpa.qq.com/msgrd?v=3&uin=2881301705&site=qqq&menu=yes" target="_blank" >
							  <c-button type="danger" size="small" @click="qqClick">{{$t('right.phone.text')}}</c-button>
						  </el-link>
					  </div>
				  </div>
				  <a-icon type="qq" class="ri_icon" />
				</el-tooltip>
			</div>
			<div class="ri_text">
				<el-tooltip placement="left">
				  <div slot="content" @mouseleave="handleMouseLeave(1)">
					  <div style="padding-top: 10px;padding-bottom: 10px;">{{$t('right.qq.text1')}}：</div>
					  <div style="padding-top: 10px;padding-bottom: 10px;">+86 0755-82056199</div>
				  </div>
				  <a-icon type="phone" class="ri_icon" />
				</el-tooltip>
			</div>
			<div class="ri_text">
				<el-tooltip placement="left">
				  <div slot="content" @mouseleave="handleMouseLeave(2)">
					  <div style="padding-bottom: 10px;width: 103px;">{{$t('right.opinion.text7')}}</div>
					  <el-image class="ri_tx_img" :src="require('@/assets/img/enterpriseQRcode2.jpg')" fit="cover"></el-image>
				  </div>
				  <a-icon type="wechat" class="ri_icon" />
				</el-tooltip>
			</div>
			<div class="ri_text">
				<el-tooltip placement="left">
				  <div slot="content" @mouseleave="handleMouseLeave(3)">
					  <div style="padding-bottom: 10px;width: 103px;">{{$t('right.opinion.text8')}}</div>
					  <el-image class="ri_tx_img" :src="require('@/assets/img/enterpriseQRcode.jpg')" fit="cover"></el-image>
				  </div>
				  <el-image class="ri_tx_img2" :src="require('@/assets/img/QRcode.png')" fit="cover"></el-image>
				</el-tooltip>
			</div>
			<div class="ri_text">
				<el-tooltip placement="left">
				  <div slot="content">
					  <div style="width: 300px;margin: 10px;">
					  	  <div style="padding-bottom: 10px;">{{$t('right.opinion.title')}}：</div>
					  </div>
					  <div style="width: 300px;margin: 10px;">
						  <div style="padding-bottom: 10px;display: flex;"><div style="color: red;">*</div>{{$t('right.opinion.text1')}}</div>
						  <div style="display: flex;">
							  <a-input style="width: 200px;" :placeholder="$t('right.opinion.input1')" v-model.trim="form.name" ></a-input>
							  <a-select v-model="nameValue" slot="addonAfter" style="width: 90px;margin-left:10px;" :getPopupContainer="triggerNode => {return triggerNode.parentNode || document.body;}">
							        <a-select-option value="先生">
							          先生
							        </a-select-option>
							        <a-select-option value="女士">
							          女士
							        </a-select-option>
							      </a-select>
						  </div>
					  </div>
					  
					  <div style="width: 300px;margin: 10px;">
					  	  <div style="padding-bottom: 10px;display: flex;"><div style="color: red;">*</div>{{$t('right.opinion.text2')}}</div>
					  	  <a-input :placeholder="$t('right.opinion.input2')" v-model.trim="form.company" />
					  </div>
					  
					  <div style="width: 300px;margin: 10px;">
					  	  <div style="padding-bottom: 10px;display: flex;"><div style="color: red;">*</div>{{$t('right.opinion.text3')}}</div>
					  	  <a-input :placeholder="$t('right.opinion.input3')" v-model.trim="form.phone" />
					  </div>
					  
					  <div style="width: 300px;margin: 10px;">
					  	  <div style="padding-bottom: 10px;">{{$t('right.opinion.text6')}}</div>
					  	  <a-input :placeholder="$t('right.opinion.input5')" v-model.trim="form.emails" />
					  </div>
					  
					  <div style="width: 300px;margin: 10px;">
					  	  <div style="padding-bottom: 10px;display: flex;"><div style="color: red;">*</div>{{$t('right.opinion.text4')}}</div>
					  	  <el-input
					  	    type="textarea"
					  	    :autosize="{ minRows: 2, maxRows: 4}"
					  	    :placeholder="$t('right.opinion.input4')" v-model="form.leavingMessage"></el-input>
					  </div>
					  
					  <div style="width: 300px;margin: 20px 10px 10px 10px;">
					  	  <!-- <c-button type="danger" size="small" @click="sendComments"></c-button> -->
						  <el-button
						    :loading="loading"
						    size="medium"
						    type="danger"
						    style="width:100%;"
						    @click.native.prevent="sendComments"
						  >
						    <span v-if="!loading">{{$t('right.opinion.text5')}}</span>
						    <span v-else>{{$t('right.opinion.text9')}}...</span>
						  </el-button>
					  </div>
				  </div>
				  <el-image class="ri_tx_img3" :src="require('@/assets/icons/feedback.png')" fit="cover"></el-image>
				</el-tooltip>
			</div>
			<div class="ri_bottom" @click="down">
				<a-icon type="down" class="ri_bo_down" />
			</div>
		</div>
	</div>
</template>

<script>
	import CButton from '@/components/CButton/index.vue'
	import { scrollTo } from '@/utils/scroll-to'
	import axios from 'axios';
	import Cookies from "js-cookie";
	import { 
		sendmail,
		saveLog
	} from '@/api/home/index.js'
	import html from '@/utils/htmlEmail'
	export default {
		components:{
			CButton
		},
		data() {
			return {
				form:{
					emails: null, //邮箱
					leavingMessage: null, //留言
					name:null,//姓名
					company:null,//公司名称
					phone:null,//电话号码
					title:null,//标题
					// ipaddress:null,//ip地址
				},
				userInfo:null,
				loading: false,
				nameValue:"先生",
			}
		},
		
		created() {
			this.userInfo = Cookies.get("userInfo2")
			if(this.userInfo){
				this.userInfo = JSON.parse(this.userInfo)
			}
			// console.log(this.userInfo)
			// axios.get('https://2023.ip138.com').then(response => {
			// 	// this.ipaddress = response.data.split("您的IP地址是：")[1].split("</title>")[0]
			// 	// console.log("ipaddress",this.ipaddress)
			// }).catch(error => {
			//     console.log(error);
			// });
		},
		
		mounted() {
			
		},
		
		watch:{
			
		},
		
		computed:{
			
		},
		
		methods:{
			upTop:function(){
				scrollTo(0, 800)
			},
			
			down:function(){
				scrollTo(document.body.scrollHeight, 800)
			},
			
			sendComments:function(){
				this.loading=true
				if(this.form.name==null){
					this.$message.error("请输入姓名")
					this.loading=false
				}else if(this.form.phone==null){
					this.$message.error("请输入电话号码")
					this.loading=false
				}else if (!(/^1[3456789]\d{9}$/.test(this.form.phone))) {
					this.$message.error("请输入正确的电话号码")
					this.loading=false
				}else if(this.form.company==null){
					this.$message.error("请输入公司")
					this.loading=false
				}else if(this.form.leavingMessage==null){
					this.$message.error("请输入留言")
					this.loading=false
				}else{
					let data = {
						actiondo:"点击发送留言",
						// ipaddress:this.ipaddress,
						uid:this.userInfo==undefined ? null : this.userInfo==null ? null : this.userInfo.id
					}
					saveLog(data).then(res=>{
						this.form.title='姓名：'+this.form.name + this.nameValue + ',留言人电话：' + this.form.phone+'公司名称：' + this.form.company + ',留言人邮箱地址：' + this.form.emails
						let arr={
							subject: this.form.title,
							content: html.emailHtml(this.form),
							type: 1
						}
						sendmail(arr).then(res => {
							if (res.code == 200) {
								this.open=false
								this.loading=false
								this.form.emails=null
								this.form.leavingMessage=null
								this.form.name=null
								this.form.company=null
								this.form.phone=null
								this.form.title=null
								
								this.$message({
									message: '邮件发送成功！',
									type: 'success'
								});
								this.$alert('您的意见我们已经收到，我们的工作人员会与您联系，衷心谢谢您的反馈！', '意见反馈', {
								          confirmButtonText: '确定',
								        });
							}else{
								this.loading=false
								this.$message.error('邮件发送失败！');
							}
						})
					})
				}
			},
			
			handleMouseLeave(e) {
				let data = {
					actiondo:e==1 ? '查看手机号' : e==2 ? '小程序' : '公众号',
					// ipaddress:this.ipaddress,
					uid:this.userInfo!==null ? this.userInfo.id : null
				}
				console.log(data)
			    saveLog(data).then(res=>{
					
				})
			},
			
			qqClick:function(e){
				let data = {
					actiondo:"点击qq",
					// ipaddress:this.ipaddress,
					uid:this.userInfo!==null ? this.userInfo.id : null
				}
				saveLog(data).then(res=>{
					
				})
			}
		}
	}
</script>

<style lang="less" scoped>
	
	@media only screen and (min-width:1920px) {
		.right{
			position: fixed;
			bottom: 150px;
			right: 0;
			z-index: 999;
			
			.ri_top{
				background: #000;
				opacity: .9;
				border-top-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;
				
				.ri_to_icon{
					color: #fff;
					font-size: 24px;
				}
			}
			
			.ri_text{
				background: #000;
				opacity: .9;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;
				
				.ri_qq{
					color: #fff;
					padding: 10px;
					width: 150px;
				}
				
				.ri_tx_img2{
					width: 40px;
					height: 40px;
				}
				
				.ri_tx_img3{
					width: 35px;
					height: 35px;
				}
				
				.ri_icon{
					color: #fff;
					font-size: 24px;
				}
			}
			
			.ri_bottom{
				background: #000;
				opacity: .9;
				border-bottom-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;
				
				.ri_bo_down{
					color: #fff;
					font-size: 24px;
				}
			}
			
			.ri_top:hover{
				opacity: .8;
			}
			
			.ri_text:hover{
				opacity: .8;
			}
			
			.ri_bottom:hover{
				opacity: .8;
			}
			
		}
		
		.ri_tx_img{
			width: 100px;
			height: 100px;
		}
	}
	
	@media only screen and (max-width:1920px) {
		.right{
			position: fixed;
			bottom: 150px;
			right: 0;
			z-index: 999;
			
			.ri_top{
				background: #000;
				opacity: .9;
				border-top-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;
				
				.ri_to_icon{
					color: #fff;
					font-size: 24px;
				}
			}
			
			.ri_text{
				background: #000;
				opacity: .9;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;
				
				.ri_qq{
					color: #fff;
					padding: 10px;
					width: 150px;
				}
				
				.ri_tx_img2{
					width: 40px;
					height: 40px;
				}
				
				.ri_tx_img3{
					width: 35px;
					height: 35px;
				}
				
				.ri_icon{
					color: #fff;
					font-size: 24px;
				}
			}
			
			.ri_bottom{
				background: #000;
				opacity: .9;
				border-bottom-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;
				
				.ri_bo_down{
					color: #fff;
					font-size: 24px;
				}
			}
			
			.ri_top:hover{
				opacity: .8;
			}
			
			.ri_text:hover{
				opacity: .8;
			}
			
			.ri_bottom:hover{
				opacity: .8;
			}
			
		}
		
		.ri_tx_img{
			width: 100px;
			height: 100px;
		}
	}
	
	@media only screen and (max-width:1919px) and (min-width:1440px) {
		.right{
			position: fixed;
			bottom: 150px;
			right: 0;
			z-index: 999;
			
			.ri_top{
				background: #000;
				opacity: .9;
				border-top-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;
				
				.ri_to_icon{
					color: #fff;
					font-size: 16px;
				}
			}
			
			.ri_text{
				background: #000;
				opacity: .9;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;
				
				.ri_qq{
					color: #fff;
					padding: 10px;
					width: 150px;
				}
				
				.ri_tx_img2{
					width: 30px;
					height: 30px;
				}
				
				.ri_tx_img3{
					width: 25px;
					height: 25px;
				}
				
				.ri_icon{
					color: #fff;
					font-size: 16px;
				}
			}
			
			.ri_bottom{
				background: #000;
				opacity: .9;
				border-bottom-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;
				
				.ri_bo_down{
					color: #fff;
					font-size: 16px;
				}
			}
			
			.ri_top:hover{
				opacity: .8;
			}
			
			.ri_text:hover{
				opacity: .8;
			}
			
			.ri_bottom:hover{
				opacity: .8;
			}
			
		}
		
		.ri_tx_img{
			width: 100px;
			height: 100px;
		}
	}
	
	@media only screen and (max-width:1439px) and (min-width:1200px) {
		.right{
			position: fixed;
			bottom: 150px;
			right: 0;
			z-index: 999;
			
			.ri_top{
				background: #000;
				opacity: .9;
				border-top-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 16px;
				cursor: pointer;
				
				.ri_to_icon{
					color: #fff;
					font-size: 16px;
				}
			}
			
			.ri_text{
				background: #000;
				opacity: .9;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 16px;
				cursor: pointer;
				
				.ri_qq{
					color: #fff;
					padding: 10px;
					width: 150px;
				}
				
				.ri_tx_img2{
					width: 30px;
					height: 30px;
				}
				
				.ri_tx_img3{
					width: 25px;
					height: 25px;
				}
				
				.ri_icon{
					color: #fff;
					font-size: 16px;
				}
			}
			
			.ri_bottom{
				background: #000;
				opacity: .9;
				border-bottom-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 16px;
				cursor: pointer;
				
				.ri_bo_down{
					color: #fff;
					font-size: 16px;
				}
			}
			
			.ri_top:hover{
				opacity: .8;
			}
			
			.ri_text:hover{
				opacity: .8;
			}
			
			.ri_bottom:hover{
				opacity: .8;
			}
			
		}
		
		.ri_tx_img{
			width: 100px;
			height: 100px;
		}
	}
	
	@media only screen and (max-width:1199px) and (min-width:768px) {
		.right{
			position: fixed;
			bottom: 150px;
			right: 0;
			z-index: 999;
			
			.ri_top{
				background: #000;
				opacity: .9;
				border-top-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;
				
				.ri_to_icon{
					color: #fff;
					font-size: 16px;
				}
			}
			
			.ri_text{
				background: #000;
				opacity: .9;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;
				
				.ri_qq{
					color: #fff;
					padding: 10px;
					width: 150px;
				}
				
				.ri_tx_img2{
					width: 30px;
					height: 30px;
				}
				
				.ri_tx_img3{
					width: 25px;
					height: 25px;
				}
				
				.ri_icon{
					color: #fff;
					font-size: 16px;
				}
			}
			
			.ri_bottom{
				background: #000;
				opacity: .9;
				border-bottom-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;
				
				.ri_bo_down{
					color: #fff;
					font-size: 16px;
				}
			}
			
			.ri_top:hover{
				opacity: .8;
			}
			
			.ri_text:hover{
				opacity: .8;
			}
			
			.ri_bottom:hover{
				opacity: .8;
			}
			
		}
		
		.ri_tx_img{
			width: 100px;
			height: 100px;
		}
	}
	
	@media only screen and (max-width:767px) {
		.right{
			position: fixed;
			bottom: 150px;
			right: 0;
			z-index: 999;
			
			.ri_top{
				background: #000;
				opacity: .9;
				border-top-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;
				
				.ri_to_icon{
					color: #fff;
					font-size: 12px;
				}
			}
			
			.ri_text{
				background: #000;
				opacity: .9;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;
				
				.ri_qq{
					color: #fff;
					padding: 10px;
					width: 100px;
				}
				
				.ri_tx_img2{
					width: 20px;
					height: 20px;
				}
				
				.ri_tx_img3{
					width: 20px;
					height: 20px;
				}
				
				.ri_icon{
					color: #fff;
					font-size: 12px;
				}
			}
			
			.ri_bottom{
				background: #000;
				opacity: .9;
				border-bottom-left-radius: 10px;
				display: flex;
				align-items: center;
				justify-content: center;
				padding: 20px;
				cursor: pointer;
				
				.ri_bo_down{
					color: #fff;
					font-size: 12px;
				}
			}
			
			.ri_top:hover{
				opacity: .8;
			}
			
			.ri_text:hover{
				opacity: .8;
			}
			
			.ri_bottom:hover{
				opacity: .8;
			}
			
		}
		
		.ri_tx_img{
			width: 100px;
			height: 100px;
		}
	}
</style>